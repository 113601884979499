/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = {
    siteName: 'nathan francy',
    siteTagline: 'software developer in kansas city',
  };

  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      {/* <link rel="stylesheet" href="/stylesheets/style.css?v=asd98a0fs9a0fh0gd09fg" /> */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-40113357-1"></script>
      <Header name={data.siteName} tagline={data.siteTagline} />
      <div>
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
