import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SectionTitleIcon from './sectionTitleIcon';
import Section from "./section";

const ExperienceSummary = () => {
  const data = useStaticQuery(graphql`
    query {
        allContentfulExperience {
            edges {
                node {
                    title
                    timeframe
                    location
                    bullets
                    tags
                    date
                }
            }
        }
    }`)
    const experiences = data.allContentfulExperience.edges.map(edge => edge.node).sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0)).reverse()

    return (
        <Section
            titleContent={
                <SectionTitleIcon variant="green" icon="work" label="Experience & Skills" />
            }
            bodyContent={
                <div>
                    {experiences.map((value, i) => {
                        return <Experience key={i} experience={value} />
                    })}
                </div>
            }
        />
    );
}

const Experience = ({ experience }) => {
    return (
        <div>
            <p className="subtitle">
                <span>{experience.title}
                    <span className="location">{experience.location}</span>
                </span>
                <span className="right">{experience.timeframe}</span>
            </p>
            <ul>
                {experience.bullets.map((value, index) => {
                    return <li key={index}>{value}</li>
                })}
            </ul>
            <p className="skills">
                {experience.tags.map((value, i) => {
                    return <span key={i}>{value}</span>
                })}
            </p>
        </div>
    )
}

export default ExperienceSummary
