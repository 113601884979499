import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import SectionTitleIcon from './sectionTitleIcon';
import Section from './section';

const Project = ({ project }) => {
  const _getTitle = (title, url) => {
      if (!url) return <p className="subtitle">{title}</p>
      return <p className="subtitle"><a href={url} target="_blank" rel="noopener noreferrer">{title}</a></p>
  }

  return (
      <div>
          {_getTitle(project.title, project.url)}
          <ul>
              {project.bullets.map((value, i) => {
                  return <li key={i}>{value}</li>
              })}
          </ul>
          <p className="skills">
              {project.tags.map((value, i) => {
                  return <span key={i}>{value}</span>
              })}
          </p>
      </div>   
  )
}

const ProjectSummary = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProject {
        edges {
          node {
            title
            url
            bullets
            tags
            order
          }
        }
      }
    }  
  `)
  const projects = data.allContentfulProject.edges.map(edge => edge.node).sort(function(a, b){return a.order - b.order})
  
  return (
    <Section
      titleContent={
        <SectionTitleIcon variant="blue" icon="extension" label="Projects" />
      }
      bodyContent={
        <div>
          {projects.map((value, i) => {
            return (
              <Project key={i} project={value} />
            );
          })}
        </div>
      }
    />
  );
};

export default ProjectSummary