import React from "react"

const SectionTitleIcon = ({ variant, icon, label }) => (
    <div className={`section-title-icon ${variant}`}>
        <i className="material-icons">{icon}</i>
        <div className="icon-text">{label}</div>
    </div>
)

export default SectionTitleIcon
