import React from "react"

import Layout from "../components/layout"
// import SEO from "../components/seo"
import ExperienceSummary from "../components/experience"
import AboutMeBlurbs from "../components/aboutMeBlurbs"
import ProjectSummary from "../components/projects"
import Education from "../components/education"

const IndexPage = () => {
  return (
    <Layout>
      <div id="wrapper">
        <AboutMeBlurbs />
        <ExperienceSummary />
        <ProjectSummary />
        <Education />
      </div>
    </Layout>
  )
}

export default IndexPage