import React from 'react';

const Section = ({ titleContent, bodyContent }) => {
  return (
    <div className="row">
      <div className="title-container">
        {titleContent}
      </div>
      <div className="body-container">
        {bodyContent}
      </div>
    </div>
  );
}

export default Section;