import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Section from './section';
import SectionTitleIcon from './sectionTitleIcon';

const AboutMeBlurbs = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAboutMeBlurb {
        edges {
          node {
            text
            order
          }
        }
      }
    }  
  `)
  const aboutMeBlurbs = data.allContentfulAboutMeBlurb.edges.map(edge => edge.node).sort(function(a, b){return a.order - b.order})
  
  return (
    <Section
      titleContent={
        <SectionTitleIcon variant="red" icon="face" label="About" />
      }
      bodyContent={
        <ul>
          {aboutMeBlurbs.map((value, index) => {
            return <li key={index}>{value.text}</li>
          })}
        </ul>
      }
    />
  )
};

export default AboutMeBlurbs