// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ name, tagline }) => (
  <header className="header">
    <div className="name">{name}</div>
    <div className="tagline">{tagline}</div>
    <div>
      <a className="socialLink" href="https://github.com/nathanfrancy" target="_blank" rel="noopener noreferrer">github</a>
      <a className="socialLink" href="https://twitter.com/nathanfrancy" target="_blank" rel="noopener noreferrer">twitter</a>
      <a className="socialLink" href="https://www.linkedin.com/in/nathanfrancy/" target="_blank" rel="noopener noreferrer">linkedin</a>
    </div>
  </header>
)

Header.propTypes = {
  name: PropTypes.string,
  tagline: PropTypes.string,
}

Header.defaultProps = {
  name: ``,
  tagline: PropTypes.string,
}

export default Header
