import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import SectionTitleIcon from './sectionTitleIcon';
import Section from './section';

const Education = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAttributes {
        edges {
          node {
            name
            value
          }
        }
      }
    }  
  `)

  // TODO: yikes. fix this
  const {
    school, schoolLocation, graduated, major, degree
  } = data.allContentfulAttributes.edges.map(edge => edge.node).reduce((prev, curr) => {
    prev[curr.name] = curr.value;
    return prev;
  }, {});

  return (
    <Section
      titleContent={
        <SectionTitleIcon variant="yellow" icon="school" label="Education" />
      }
      bodyContent={
        <div>
          <p className="subtitle"><span>{school}</span><span className="right">{schoolLocation}</span></p>
          <ul>
            <li>{degree}</li>
            <li>Major: <span>{major}</span></li>
            <li>{graduated}</li>
          </ul>
        </div>
      }
    />
  );
};

export default Education